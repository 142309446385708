.root {
    background: linear-gradient(87deg, #11cdef 0, #1171ef 100%);
    padding: 80px 0 128px 0;
    position: relative;
}

@media (min-width: 768px) {
    .root {
        padding: 128px 0 128px 0;
    }
}