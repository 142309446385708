.challengesContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0;
    padding: 0 15%;
    width: 100%;
}

.card {
    display: flex;
    flex-direction: column;
    margin: 0 0 20px;
    padding: 0;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    color: #000;
    min-height: 200px;
    cursor: pointer;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    overflow: hidden;
}

.imageContainer {
    display: flex;
    justify-content: center;
}

.challengeTitle {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    padding: 10px;
    max-height: 60px;
    height: 60px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row {
    display: flex;
    flex-direction: row;
}

.typeContainer {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    padding: 0 10px;
    margin: 0;
    width: 50%;
}

.timeContainer {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    padding: 0 10px;
    text-align: right;
    width: 50%;
}

@media (min-width: 768px) {
    .challengesContainer {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        margin: 10px 0 30px;
        padding: 0;
    }

    .card {
        padding: 0 20px;
        width: calc(25% - 20px);
    }
}

@media (min-width: 1024px) {
    .card {
        width: 25%;
    }
}