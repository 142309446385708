/* ----------------------------------------------
 * Generated by Animista on 2023-4-25 11:22:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */
 @-webkit-keyframes slideRight {
    0% {
      -webkit-transform: translateX(4px);
              transform: translateX(4px);
    }
    100% {
      -webkit-transform: translateX(calc(100% + 8px));
              transform: translateX(calc(100% + 8px));
    }
  }
  @keyframes slideRight {
    0% {
      -webkit-transform: translateX(4px);
              transform: translateX(4px);
    }
    100% {
      -webkit-transform: translateX(calc(100% + 8px));
              transform: translateX(calc(100% + 8px));
    }
  }

  @-webkit-keyframes slideLeft {
    0% {
      -webkit-transform: translateX(calc(100% + 8px));
              transform: translateX(calc(100% + 8px));
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  @keyframes slideLeft {
    0% {
      -webkit-transform: translateX(calc(100% + 8px));
              transform: translateX(calc(100% + 8px));
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }
  
.root {
    padding: 0 15px;
    margin-top: -10rem;
}

.tableContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
    transition: all 0.15s ease;
}

.tableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
    padding: 1.25rem 1.5rem;
    margin-bottom: 0;
    background-color: #fff;
}

.firstHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    margin: 0;
}

.subtitle {
    color: #32325D;
    font-size: 14px;
    font-weight: 400;
}

.switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    background-color: #F6F9FC;
    border: 1px solid #707E9A;
    border-radius: 20px;

    margin-top: 8px;
    padding: 4px;
    position: relative;
}

.switchTab {
    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: #F6F9FC;
    border: none;
    border-radius: 20px;

    text-align: center;

    margin: 0;
    padding: 4px 8px;
    width: 50%;
}

.switchTab p {
    color: #696A6D;
    margin: auto;
    z-index: 2;
    font-weight: 400;
}

.switch::after {
    content: "";
    display: flex;
    flex-direction: row;
    align-items: center;

    color: #fff;
    background: #fff;
    border: 1px solid #707E9A;    
    border-radius: 20px;

    margin: 0;
    padding: 4px 8px;

    position: absolute;
    left: 4px;
    width: calc(50% - 8px);
    height: calc(100% - 8px);
    z-index: 1;
}

.active p {
    color: #004DFF;
    font-weight: 700;
}

.right.switch::after {
    -webkit-animation: slideRight 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slideRight 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.left.switch::after {
    -webkit-animation: slideLeft 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slideLeft 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.table {
    align-items: center;

    width: 100%;
    overflow-x: auto;
}

.thead, tbody {
    width: 100%;
}

.titleCol {
    display: flex;
    flex-direction: column;

    overflow: hidden;
    padding: var(--cell-padding-top-bottom) var(--cell-padding-sides);
    margin: 0;
    height: 64px;
    width: 300px;
}

.tr {
    max-height: 64px;
    border-bottom: 1px solid rgba(0,0,0,.05)
}

.th {
    background-color: var(--blueGray);
    color: var(--darkGray);

    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 10.4px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #e9ecef;
}

.th, .td {
    padding: var(--cell-padding-top-bottom) var(--cell-padding-sides);
    max-height: 64px;
}

.td {
    font-size: 14px;
}

.name {
    font-weight: 600;
}

.actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.addButton {
    color: #fff;
    background-color: #004dff;
    border-color: #004dff;
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    border-radius: 0.375rem;
    font-size: .75rem;
}

.edit {
    color: #fff;
    background-color: #5e72e4;
    border-color: #5e72e4;
}

.assign {
    color: #fff;
    background-color: #10aa98;
    border-color: #10aa98;
}

.delete {
    color: #fff;
    background-color: #f5365c;
    border-color: #f5365c;
}

.tableFooter {
    padding: 1.5rem 1.5rem;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
}

.input {
    width: 100%;
    padding: 0.625rem 0.75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e6e6e;
    background-color: #fff;
    border: 0;
    margin: auto 0;
}

.inputHolder {
    margin-bottom: 24px;
    padding: 0 15px;
    width: 100%;
}

.inputGroup {
    display: inherit;
    align-items: end;
    width: 100%;
    padding: 3px;
    margin-left: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.375rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    border: 0;
    transition: box-shadow .15s ease;
}

.iconHolder {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 0.625rem 0.75rem;
}

.icon {
    display: flex;
    align-items: center;
    margin: auto 0;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.5;
    color: #adb5bd;
    text-align: center;
    transition: all .2s cubic-bezier(.68,-.55,.265,1.55);
}
.searchButton{ 
    align-items: center;
    margin: auto 6px;
    color: #fff;
    background-color: #004dff;
    border-color: #004dff;
    padding: 12px 10px;
    width: max-content;
    line-height: 1.5;
    border-radius: 0.375rem;
    font-size: .75rem;
    text-align: center;
}

.noData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}   

.noData p {
    color: #6e6e6e;
    margin: 0;
}


.modal {
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: none;
    width: 60%;
}

.modalContent {
    border-radius: 25px;
    padding: 12px;
}

.modalBody {
    position: relative;
    flex: 1 1 auto;
    padding: 0 1.5rem;
}

.modalTitle {
    margin-bottom: 0;
    line-height: 1.1;
    margin-bottom: 16px;
}

.modalText {
    text-align: left;
}

.modalText p {
    margin-bottom: 2px;
}

.modalText b {
    font-weight: 700;
}

.confirmButton {
    background-color: #fff;
    border: 1px solid #004dff;
    border-radius: 0.25em;
    color: #004dff;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
}

.cancelButton {
    background-color: #004dff;
    border: 1px solid #004dff;
    border-radius: 6px;
    color: #fff;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
}

.warningIcon {
    width: 72px!important;
    height: 72px!important;
    border: none!important;
    border-radius: 0!important;
    background-image: url('../../assets/warning.png');
}

@media (min-width: 768px) {
    .root {
        padding: 39px;
    }

    .titleCol {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .root {
        padding: 0 39px;
        margin-top: -100px;
    }
}