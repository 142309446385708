.main {
    position: relative;
}

.footerContainer {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .main {
        margin-left: 250px;
    }

    .footerContainer {
        margin-top: 96px;
    }
}