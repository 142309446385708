/* * GENERAL */
/* Box Shadow */
.sofiaButton,
.chatContainer,
.chatContent .loading,
.chatContent .message,
.botReply p,
.botError p {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
}

/* Flex */
.sofiaButton,
.chatHeader,
.chatContent,
.botReply,
.botError,
.errorBubble,
.errorActions,
.botLoading,
.chatBox,
.sendButton {
    display: flex;
}

/* Hover and disabled */
.sofiaButton:hover,
.sendButton:hover,
.sendButton:disabled{
    opacity: 0.75;
}

/* Justify center */
.sofiaButton,
.sendButton {
    justify-content: center;
}

/* Justify between */
.chatHeader,
.chatBox {
    justify-content: space-between;
}

/* Align center */
.sendButton,
.chatBox,
.chatHeader,
.sofiaButton {
    align-items: center;
}

/* * sofIA Toggle Button */
.sofiaButton {
    right: 50px;
    bottom: 50px;
    position: fixed;
    border: none;
    padding: 0;
    margin: 0;
    background: none;
    transition: all 0.33s;
    border-radius: 50%;
    z-index: 1;
}

.sofiaButton img {
    border-radius: 50%;
    width: 50px;
}

/* sofIA Chatbot */

/* * Chat Container */
.chatContainer {
    position: fixed;
    right: 50px;
    bottom: calc(50px + 50px + 1rem);
    width: 400px;
    font-family: 'Open Sans', sans-serif;
    border-radius: 0.5rem;
    /* display: none; */
    z-index: 100001;
    animation: grow-bottom 0.25s ease-in-out;
}

/* * Chat Header */
.chatHeader {
    background-color: #fff;
    padding: 1rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border: 1px solid #EBEBEA;
    flex-direction: row;
}
.chatHeader h6 {
    /* color: #fff; */
    color: #54565A;
    margin: 0;
    font-size: 16px;
    /* text-align: center; */
}
.chatHeader button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
}
.chatHeader button:hover {
    cursor: pointer;
}

.chatHeader button img {
    width: 24px;
}

/* * Chat Content */

.chatContent {
    background-color: #EBEBEA;
    padding: 1rem;
    overflow-y: auto;
    gap:1rem;
    min-height: 300px;
    max-height: 400px;
    flex-direction: column;
}

/* Hide Scrollbar */
/* Chrome based */
.chatContent::-webkit-scrollbar {
    display: none;
}
/* Microsoft based */
.chatContent {
    -ms-overflow-style: none;
}
/* Gecko based */
.chatContent {
    scrollbar-width: none;
}

/* * User Message & Bot Reply */
.userMessage,
.botReply p,
.botError p {
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 14px;
    line-height: 1.5;
}

.userMessage,
.botReply p,
.errorBubble {
    max-width: 70%;
}

.userMessage,
.botReply p,
.botError p,
.loadingBubble {
    
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

/* Bot Messages */

.loadingBubble,
.botReply p,
.botError p {
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    align-self: flex-start;
}

.loadingBubble,
.botReply p {
    background-color: #fff;
    color: #54565A;
}

.errorBubble {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
}

.errorBubble p {
    background-color: #ff000013;
    color: #C10230;
}
/* Error Actions */
.errorActions {
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-start;
}
.errorActions button {
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
}
.retryButton {
    color: #54565A;
}
.deleteButton {
    color: #C10230;
}
.errorActions button:hover {
    text-decoration: underline;
}
.retryButton:hover {
    color: #004cff;
}

.botLoading {
    order: 3;
}

/* Loading bubble */
.loadingBubble{
    padding: 0.5rem 0.75rem;
    width: fit-content;
}
/* Loading dot */
.loadingBubble div {
    color: #54565A;
    border-radius: 50%;
    height: 4px;
    width: 4px;
    margin-right: 2px;
    vertical-align: middle;
    display: inline-block;
    animation: lodadingAnimation 1.8s infinite ease-in-out;
}
/* Animation delays */
.loadingBubble .firstDot {
    animation-delay: 200ms;
}
.loadingBubble .middleDot {
    animation-delay: 300ms;
}
.loadingBubble .lastDot {
    animation-delay: 400ms;
    margin-right: 0;
}

/* Reply container */
.botReply,
.botError,
.botLoading {
    gap: 1rem;
    align-items: flex-start;
}
/* Bot text bubble */
.botReply p,
.botError p {
    margin: 0;
}
/* Bot profile picture */
.botReply img,
.botError img,
.botLoading img {
    width: 35px;
    border-radius: 50%;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
}

/* User message */
.userMessage {
    align-self: flex-end;
    background-color: #004cff;
    color: #fff;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 10px;
}

/* * Send Button * */
.sendButton {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    opacity: 1;
    transition: all 0.25s;
    cursor: pointer;
}

.sendButton img {
    height: 24px;
    transform: rotate(90deg);
}

/* * Chat Box * */

/* .chatBox {
    padding: 1rem;
    background-color: #fff;
} */

/* Form */
.chatBox {
    background-color: #fff;
    padding: 0.75rem;
    gap: 0.75rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.chatBox input {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    padding: 0.5rem 0.75rem;
    border-radius: 10px;
    border: none;
    font-size: 14px;
    background-color: #EBEBEA;
}

.chatBox input:disabled {
    background-color: #ddd;
}

.chatBox input:disabled,
.chatBox .sendButton:disabled{
    cursor: auto;
}

.chatBox input:focus {
    outline: none;
}


/* * Loading Animation * */
@keyframes lodadingAnimation {
    0% {
        transform: translateY(0px);
        background-color: rgb(84, 86, 90, 0.7);
    }
    28% {
        transform: translateY(-7px);
        background-color: rgb(84, 86, 90, 0.4);
    }
    44% {
        transform: translateY(0px);
        background-color: rgb(84, 86, 90, 0.2);
    }
}

@keyframes grow-bottom {
    from {
        opacity: 0;
        transform: scaleY(0) translateY(100%);
    }
    to {
        opacity: 1;
        transform: scaleY(1);
    }
}