.root {
    display: flex; 
    flex-direction: column;

    width: 100%;
}

.description {
    color: #000;
    padding: 40px 50px;
    font-size: 16px;
    margin: 0;
    font-weight: 400;
}

.teamContainer {
    padding: 10px 8%;
}

.learnMoreTitle {
    color: #000;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin: 50px 0 10px;
}

.theChallengeDescription {
    color: #000;
    font-size: 16px;
    padding: 0 30px 20px;
    font-weight: 400;
}

.theTitle {
    color: #000;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    padding: 50px 30px 0;
    margin: 0;
}

.theSubTitle {
    color: #004DFF;
    font-size: 36px;
    font-weight: 700;
    padding: 50px 30px 10px;
    text-align: center;
    margin: 0;
}

.getMindDescription {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    padding: 0 30px 60px;
    margin: 0;
}

.getMindSurvey {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    margin-top: 120px;
    padding: 0 30px 60px;
    margin-bottom: 0;
}

.buttonMedium {
    margin: 0 0 30px 20px;
    width: 90px;
}

.evidenceContainer {
    display: block;
    background: url("../../assets/bg-line.png");
    background-size: 700px 1000px;
    background-position: top 5px center;
    background-repeat: repeat;
    padding: 0 20px;
}

.imageContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 50%;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.textContainer {
    width: 100%;
}

.reverse {
    display: flex; 
    flex-direction: column-reverse;
    justify-content: flex-start;
    width: 100%;
}

.surveyContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    background: #fff;
    border-top: 1px solid #afafaf;
    border-bottom: 1px solid #afafaf;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0 10px;
    width: 100%;
}

.surveyButton {
    background: transparent;
    border: none;
}

.surveyButton:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.image {
    height: 250px;
    margin-top: 20px;
    width: auto;
}

.likeButton {
    color: transparent;
    background: url("../../assets/thumb-up.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 12px;
    margin: 12px;
    border: none;
}

.dislikeButton {
    color: transparent;
    background: url("../../assets/thumb-down.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 12px;
    margin: 12px;
    border: none;
}
.card {
    position: relative;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
}

.cardButton {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;

    width: 100%;
}

.imageBanner {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    object-fit: cover;
    width: 100%;
}

.contentCard {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;

    padding: 30px;
    width: 100%;
}

.titleCard {
    font-size: 20px;
    font-weight: 600;
    bottom: 0;
    margin: 0;
    line-height: 1.334;
    z-index: 1;
}

@media (min-width: 768px) {
    .teamContainer {
        padding: 10px 80px;
    }

    .theChallengeDescription {
        padding: 0 100px 20px;
    }

    .theTitle {
        text-align: left;
    }

    .theSubTitle {
        text-align: left;
    }

    .evidenceContainer {
        display: flex;
        flex-direction: column;
        background-repeat: no-repeat;
        padding: 0 100px;
    }

    .textContainer {
        width: 50%;
    }

    .container {
        flex-direction: row;
    }

    .reverse {
        flex-direction: unset;
    }

    .reverse .textContainer .theTitle {
        text-align: right;
    }

    .reverse .textContainer .theSubTitle {
        text-align: right;
    }
}