.drawer {
    width: 300px;
    background: #F6F6F6;
}

.drawerPaper {
    top: 0 !important; 
    width: 300px;
    height: 100%;
    background: #F6F6F6;
}

.container {
    display: flex;
    flex-direction: column;
    background: #F6F6F6;
    height: 100%;
    overflow: auto;
    padding: 80px 0 0;
}

.title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 16px;
    font-weight: 600;
    margin: 24px 0;
}

.backButton {
    margin-right: 10px !important;
    height: fit-content;
}

.challenge {
    padding: 12px 0;
}

.current {
    color: #004dff;
}

/* Lists */

.list {
    display: block;
    position: relative;
    list-style: none;
    background: #F6F6F6;
    margin: 0;
    padding: 0 0 10px;
    width: 100%;
}

.listItem {
    background: transparent;
    border: none;
    padding: 8px 16px;
    text-align: left;
    width: 100%;
}

.listItem:hover {
    background: transparent;
}

.text {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin: 4px 0;
    line-height: 1.5;
    color: #004DFF !important;
}

.minutes {
    font-size: 14px!important;
    font-weight: 400!important;
    margin: 4px 0;
    line-height: 1.5;
}

@media (min-width: 960px) {
    .drawerPaper {
        top: 85px !important; 
        width: 300px;
        height: calc(100% - 85px)!important;
    }

    .container {
        padding: 0;
    }
}