.root {
    display: flex;
    flex-direction: column;
    
    min-height: calc(100vh - var(--footer-height-sm) - var(--mobile-navbar-height));
}

@media (min-width: 768px) {
    .root {
        min-height: calc(100vh - var(--footer-height));
    }
}