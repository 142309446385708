.grid {
    display: flex;
    flex-grow: 1;
    background-color: #fff;
}

.content {
    flex-grow: 1;
}

.footer {
    background: #F6F6F6;
    border-bottom: 1px solid #afafaf;
}