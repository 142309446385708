.container,
.popup {
    border-radius: 25px;
}

.confirmButton {
    background-color: #fff;
    border: 1px solid #004dff;
    border-radius: 0.25em;
    color: #004dff;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
}

.cancelButton {
    background-color: #004dff;
    border: 1px solid #004dff;
    border-radius: 6px;
    color: #fff;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
}