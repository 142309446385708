.root {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
}

.teamTitle {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Baskerville BT';
}

.fourContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    width: 50%;
}

.card {
    display: flex;
    flex-direction: column;
    background: url("../../../../assets/07-fig.png");
    background-color: #fff;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    min-height: 230px;
    width: 157px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    margin: 0 auto;
}

.imageContainer {
    display: flex;
    margin-top: 30px;
}

.avatarImage {
    border-radius: 50%;
    margin: 0 auto;
    width: 60px;
    height: 60px;
}

.userNames {
    color: #000;
    font-size: 17px;
    font-weight: 600;
    font-family: 'Baskerville BT';
    margin-top: 19px;
    padding: 0 10px;
    text-align: center;
}

.typeContainer {
    color: #000;
    font-size: 11px;
    font-weight: 600;
    font-family: 'Baskerville BT';
    padding: 0 10px;
    text-align: center;
}

.socialContainer {
    display: flex; 
    justify-content: center;
    margin: auto auto 5px;
}

.social {
    margin: 0 3px 5px;
    width: 18px;
    height: 18px;
    border-radius: 2px;
}

.instagram {
    background: radial-gradient(circle at 30% 107%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

.facebook {
    background: #3b5998;
}

.linkedIn {
    background: #04689B;
}

.twitter {
    background: #55ADEE;
}

.discord {
    background: #5864f1;
}

.gitHub {
    background: #000000;
}

.behance {
    background: #004bc0;
}

@media (min-width: 768px) {
    .fourContainer {
        width: calc(100% / 6);
    }
}