.backdrop {
    color: #fff;
    z-index: 1201;
}

.root {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    min-height: 700px;
    height: 100%;
}

.footerContainer {
    position: relative;
    display: flex;
    flex-grow: 1;
    width: 100%;
}

@media (min-width: 992px) {
    .root {
        min-height: calc(100vh - 85px);
        height: auto;
    }
}