.root {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.logo {
    margin: 16px auto;
    width: 150px;
}

.maintainance {
    margin: 16px auto;
    width: 150px;
}

.title {
    color: #000000;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    width: 40%;
    margin: 0 auto;
}

.description {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    width: 40%;
    margin: 16px auto;
}

@media (max-width: 768px) {
    .title {
        width: 80%;
    }

    .description {
        width: 80%;
    }
}