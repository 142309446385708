.horizontalList {
    display: flex;
    flex-direction: row;
    padding: 0;
    font-size: 18px;
    padding-right: 20px;
}

.muiBox {
    display: flex;
    flex-grow: 1;
    padding: 0;
    justify-content: right;
    margin: 0;
}

.appBarItem {
    padding: 0;
}

.listItem {
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: flex-start;
    text-decoration: none;
    color: #323132;
    width: auto;
    font-family: Hind Madurai, sans-serif;
    font-weight: bold;
    padding: 16px 30px;
}

.listItem:hover {
    color: #5e72e4;
    text-decoration: none;
}

.active {
    color: #004DFF;
    padding: 26px 30px 21px;
    border-bottom: 5px solid #004DFF;
}

.text {
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 4px;
    margin-bottom: 4px;
}