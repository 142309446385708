.root {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: transparent;
}

.container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
}

.backLink {
    display: none;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: var(--h4-font-size);
    font-weight: 600;

    text-transform: uppercase;

    margin: 0;
    padding: 0;
    width: fit-content;
}

.backLink:hover {
    filter: brightness(0.9);
    cursor: pointer;
}

.backLink:disabled {
    cursor: auto;
}

.backLink:disabled:hover {
    filter: none;
}

.separator {
    color: #fff;
    margin: 0 8px;
}

/* Profile button dropdown */

.dropdown {
    display: none;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    align-items: center;
}

.dropdownContainer {
    display: inline-block;
    position: relative;
}

.dropToggle {
    display: flex;
    color: #54565a;
    padding: 0.25rem 0.75rem;
    align-items: center;
    cursor: pointer;
}

.avatar {
    color: #fff;
    background-color: #adb5bd;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 0.875rem;
}

.avatarImg {
    width: 100%;
    border-radius: 50%;
}

.dropdownMenu {
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 12rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #525f7f;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.15);
    border-radius: 0.4375rem;
    box-shadow: 0 50px 100px rgb(50 50 93 / 10%), 0 15px 35px rgb(50 50 93 / 15%), 0 5px 15px rgb(0 0 0 / 10%);
}

.dropdownHeader {
    display: block;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 0.625rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #f6f9fc;
    white-space: nowrap;
}

.dropTitle {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5;
    color: #32325d;
    margin: 0;
}

.dropdownItem {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.icon {
    margin-right: 1rem;
    font-size: 1rem;
    vertical-align: -17%;
}

.text {
    color: hsla(0,0%,100%,.95);
    font-size: .875rem;
    font-weight: 600;
    margin-left: 0.5rem;
    margin-bottom: 0;
}

.show {
    display: block;
}

@media (min-width: 768px) {
    .root {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .container {
        padding-left: 39px;
        padding-right: 39px;
    }

    .backLink {
        display: inline-block;
    }

    .dropdown {
        display: flex;
    }
}