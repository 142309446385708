.form {
    display: flex;
    flex-direction: column;
    
    height: 100%;
}

.label {
    font-size: 14px;
    font-weight: 700;
    margin: 16px 0 4px;
}

.input {
    width: 100%;
    color: #6e6e6e;
    background-color: #fff;
    border: 0;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000029;

    margin: auto 0;
    padding: 8px 12px;

}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    margin-top: 24px;
}

.cancel {
    background: transparent;
    border: 0;
    color: #000;
    font-weight: 700;

    cursor: pointer;
}