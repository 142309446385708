.card {
    display: flex; 
    flex-wrap: wrap;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 8%), 0px 3px 4px 0px rgb(0 0 0 / 7%), 0px 1px 8px 0px rgb(0 0 0 / 7%);
    min-height: 100px;
    border-radius: 10px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;

    width: 100%;
}

.imgHolder {
    margin: 0;
    width: 100%;
    height: 150px;
}

.cardContainer {
    font-family: 'Hind Madurai'!important;
    width: 100%;
}

.cardFooter {
    display: flex;
    margin: 25px 0 12px;
    width: 100%;
}

.cardHalf {
    width: 50%;
}

.squareImage {
    width: 100%;
    height: calc(100% - 10px);
    object-fit: cover;
}

.titleChallenge {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    padding: 15px 30px 0;
}

.subHeader {
    color: #222;
    font-size: 16px;
    font-weight: 700;
    padding: 15px 30px 0;
    margin: 8px 0;
}

.contentChallenge {
    color: #000;
    font-size: 14px;
    padding: 0 30px 0;
    white-space: pre-line;
}

.inProcessText {
    color: #004DFF;
    padding: 30px 0 0 30px;
    font-size: 16px;
    font-weight: 600;
}

.inPendingText {
    color: #66676a;
    padding: 30px 0 0 30px;
    font-size: 16px;
    font-weight: 600;
}

.buttonNextContainer {
    display: flex;
    justify-content: flex-end;
    padding: 25px 30px 0 0;
    width: 50%;
}

.buttonContinue {
    background: #004DFF;
    border: 2px solid #004DFF;
    border-radius: 20px;
    color: #FFF;
    max-height: 35px;
    height: 35px;
    padding: 8px 16px;
    width: 95px;
}

.buttonContinue:hover {
    background: #fff;
    color: #004DFF;
    border: 2px solid #004DFF;
}

@media (min-width: 768px) {

    .cardContainer {
        font-family: 'Hind Madurai'!important;
        width: 66.67%;
    }
 
    .imgHolder {
        margin: 0;
        width: 33.33%;
        height: auto;
    }

    .container {
        width: 66.67%;
    }

    .title {
        margin-top: 30px;
    }

    .challengesContainer {
        margin: 30px 0 0 0;
    }

    .fourContainer{
        padding: 0 10px;
    }

    .fourContainerCenter {
        padding: 0 10px;
    }

    .fourContainerLast {
        padding: 0 0 0 20px;
    }

    .sixContainer {
        margin-bottom: 30px;
        padding-right: 20px;
    }

    .sixContainerLast {
        margin-top: 30px;
        padding-left: 20px;
    }

    .imageContainerSlider {
        display: block;
    }

    .partnerContainer {
        flex-grow: 0;
        max-width: 41.666667%;
        flex-basis: 41.666667%;
        padding: 20px;
        min-height: 500px;
    }

    .challengeBold {
        text-align: center;
        color: #010101;
        margin-top: 4px;
        font-weight: 700;
        font-size: 32px;
        padding: 0 20px;
        width: 60%;
        margin: 0 auto;
    }

    .noChallengeSubtitle {
        text-align: center;
        color: #010101;
        margin-top: 4px;
        font-weight: 500;
        padding: 0 20px;
        width: 60%;
        margin: 0 auto;
    }

    .assesmentTitle {
        font-size: 48px;
    }

    .assesmentSubtitle {
        margin: 0 auto;
        width: 50%;
        font-size: 20px;
    }

    .lockIcon {
        font-size: 140px;
    }
    
    .blocks {
        display: flex;
        flex-flow: row;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    .image {
        margin: auto;
        width: 20%;
        height: auto;
    }
}