.root {
    padding: 0 32px;
    font-size: 16px;
    font-weight: 500;
}

.toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    border: 0;
    border-radius: 0.375rem;
    color: #6e6e6e;
    transition: box-shadow 0.15s ease;
    padding: 0.625rem 0.75rem;
    width: 100%;
}

.input {
    display: block;
    width: 100%;
    min-height: 100px;
    padding: 0.625rem 0.75rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0 0 0.375rem 0.375rem;
    border: 0;
}

.option {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 10px !important;
    color: #000;
    height: calc(1.5em + 1.25rem + 2px);
    width: max-content;
    min-width: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
}

.select {
    display: block;
    width: max-content;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e6e6e;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0 0 0.375rem 0.375rem;
    border: 0;
    margin-right: 0.5rem;
}

/* THEME */
.quote {
    margin: 0 0 10px 20px;
    font-size: 15px;
    color: #6e6e6e;
    border-left-color: #ced0d4;
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
}

.bold {
    font-weight: 700;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.code {
    background-color: #1e1e1e;
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 48px;
    line-height: 1.53;
    font-size: 13px;
    margin: 8px 0;
    tab-size: 2;
    overflow-x: auto;
    position: relative;
    color: #fff;
    width: 100%;
}

.code:before {
    content: attr(data-gutter);
    position: absolute;
    background-color: #0f0f0f;
    left: 0;
    top: 0;
    border-right: 1px solid #777;
    padding: 8px;
    color: #777;
    white-space: pre-wrap;
    text-align: right;
    min-width: 25px;
}

.atrule {
    color: #e06c75;
}

.attr {
    color: #e06c75;
}

.boolean {
    color: #e06c75;
}

.builtin {
    color: #e06c75;
}

.cdata {
    color: #e06c75;
}

.char {
    color: #e06c75;
}

.class {
    color: #e06c75;
}

.className {
    color: #e06c75;
}

.comment {
    color: #229977;
}

.constant {
    color: #035af0;
}

.deleted {
    color: #e06c75;
}

.doctype {
    color: #e06c75;
}

.entity {
    color: #e06c75;
}

.function {
    color: #edf8a7;
}

.important {
    color: #e06c75;
}

.inserted {
    color: #e06c75;
}

.keyword {
    color: #2504dc;
}

.namespace {
    color: #e06c75;
}

.number {
    color: #e06c75;
}

.operator {
    color: #fff;
}

.prolog {
    color: #e06c75;
}

.property {
    color: #e06c75;
}

.punctuation {
    color: #e06c75;
}

.regex {
    color: #e06c75;
}

.selector {
    color: #e06c75;
}

.string {
    color: #e06c75;
}

.symbol {
    color: #e06c75;
}

.tag {
    color: #e06c75;
}

.url {
    color: #e06c75;
}

.variable {
    color: #229977;
}

/* END THEME */