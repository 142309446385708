.container {
    border-radius: 0px;
}

.buttons2,
.buttons {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.confirmButton {
    background-color: #fff;
    border: 1px solid #004dff;
    border-radius: 0.25em;
    color: #004dff;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
}

.cancelButton {
    background-color: #004dff;
    border: 1px solid #004dff;
    border-radius: 6px;
    color: #fff;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
}

.none {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 6px;
    color: #000;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
}

.modalTitle {
    color: #000;
    font-weight: 700;
    margin-bottom: 0;
    margin-bottom: 16px;
}

.delete {
    color: #fff;
    background-color: #f5365c;
    border-color: #f5365c;
}

@media (min-width: 768px) {
    .buttons {
        justify-content: space-between;
    }

    .buttons {
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
    }

    .none,
    .cancelButton,
    .confirmButton {
        width: fit-content;
    }
    
}