.root {
    padding: 0 15px;
    margin-top: -10rem;
}

.confirmButton {
    background-color: #f5365c;
    border: 1px solid #f5365c;
    border-radius: 0.25em;
    color: #fff;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
}

.cancelButton {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 6px;
    color: #000;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
}

.warningIcon {
    width: 72px!important;
    height: 72px!important;
    border: none!important;
    border-radius: 0!important;
    background-image: url('../../../../assets/warning.png');
}

.tableContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
    transition: all 0.15s ease;
}

.darkTableContainer {
    background-color: #f7fafc!important;
}

.tableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
    padding: 1.25rem 1.5rem;
    margin-bottom: 0;
    background-color: #fff;
}

.title {
    margin: 0;
}

.form {
    display: flex;
    flex-direction: column;
    padding: 24px;
}

.inputsResponsive {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.inputs {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.secondaryInputs {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.inputHolder {
    margin-bottom: 24px;
    padding: 0 15px;
    width: 100%;
}

.halfInputHolder {
    margin-bottom: 24px;
    padding: 0 15px;
    width: 100%;
}

.inputHolderSmall {
    margin-bottom: 24px;
    padding: 0 15px;
    width: 50%;
}

.input {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e6e6e;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.375rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    border: 0;
    transition: box-shadow .15s ease;
}
.textarea {
    height: auto;
}

.checkboxContainer {
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 3rem;
}

.checkbox {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1.25rem;
    height: 1.375rem;
    opacity: 0;
}

.checkboxLabel {
    position: relative;
    vertical-align: top;
    margin-bottom: 0;
    cursor: pointer;
    font-size: .875rem;
}

.checkboxLabel::before {
    pointer-events: none;
    background-color: #fff;
    box-shadow: none;
    position: absolute;
    top: 0.125rem;
    left: -3rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    content: "";
    border: 1px solid #cad1d7;
    transition: all .2s cubic-bezier(.68,-.55,.265,1.55);
    border-radius: 0.25rem;
}

.checkbox:checked~.checkboxLabel::before {
    color: #fff;
    border-color: #5e72e4;
    background-color: #5e72e4;
    box-shadow: none;
}

.checkboxLabel::after {
    position: absolute;
    top: 0.125rem;
    left: -3rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    content: "";
}

.checkbox:checked~.checkboxLabel:after {
    background: 50%/50% 50% no-repeat;
    background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8"><path fill="%23fff" d="M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z"/></svg>');
}

.table {
    align-items: center;

    width: 100%;
    overflow-x: auto;
}

.thead, tbody {
    width: 100%;
}

.titleCol {
    display: flex;
    flex-direction: column;

    overflow: hidden;
    padding: var(--cell-padding-top-bottom) var(--cell-padding-sides);
    margin: 0;
    height: 64px;
    width: 300px;
}

.tr {
    max-height: 64px;
    border-bottom: 1px solid rgba(0,0,0,.05)
}

.th {
    background-color: var(--blueGray);
    color: var(--darkGray);

    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: .65rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #e9ecef;
}

.th, .td {
    padding: var(--cell-padding-top-bottom) var(--cell-padding-sides);
    max-height: 64px;
}

.name {
    font-size: .8125rem;
    font-weight: 600;
}

.actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.addButton {
    color: #fff;
    background-color: #004dff;
    border-color: #004dff;
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    border-radius: 0.375rem;
    font-size: .75rem;
}

.edit {
    color: #fff;
    background-color: #11cdef;
    border-color: #11cdef;
}

.delete {
    color: #fff;
    background-color: #f5365c;
    border-color: #f5365c;
}

.tableFooter {
    padding: 1.5rem 1.5rem;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.375rem;
    justify-content: flex-end !important;
    gap: 24px;
}

.sprints {
    margin: 0 0 24px 0;
    padding: 0 15px;
}

.feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}

.hidden {
    display: none;
}

.noData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}   

.noData p {
    color: #6e6e6e;
    margin: 0;
}

.tabBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: #fff;

    gap: 8px;
    
    padding: 0 24px;
}

.tab {
    background-color: #fff;
    padding: 8px 12px;

    cursor: pointer;
}

.tab:hover {
    border-bottom: 3px solid #004dff;
}

.tab.active {
    background-color: #fff;
    border-bottom: 3px solid #004dff;
    font-weight: 600;
}

.newTab {
    color: #004dff;
    background-color: #fff;
    padding: 8px 12px;
    cursor: pointer;
}

.modalTitle {
    color: #000;
    font-weight: 700;
    margin-bottom: 0;
    margin-bottom: 16px;
}

.editNameButton {
    color: #004dff;
    margin-left: 8px;
    cursor: pointer;
}

.nameForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.subTab {
    display: flex;
    flex-direction: row;
    gap: 16px;

    padding: 24px 48px 0;
}

.modalityTitle {
    margin: auto 0;
}

.deleteModalityButton {
    color: #004dff;
    background-color: transparent;
    border: 1px solid #004dff;
    border-radius: 6px;
    font-size: 1em;
    margin: 0.3125em;
    padding: 6px 12px;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
}

.alert {
    position: fixed;
    top: 48px;
    left: calc(250px + 10%);

    display: flex;
    flex-direction: row;
    gap: 4px;

    background: #000;
    color: #fff;

    text-align: left;

    width: calc(80% - 250px);
    z-index: 9999;
}

.alertIcon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

@media (min-width: 992px) {
    .root {
        padding: 0 39px;
        margin-top: -100px;
    }

    .form {
        display: flex;
        flex-direction: column;
        padding: 24px 33px;
    }

    .inputsResponsive {
        flex-direction: row;
    }

    .halfInputHolder {
        width: 50%;
    }

}