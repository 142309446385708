.root {
    display: flex;
    flex-flow: column;
    margin-top: 80px;
    width: 100%;
}

.row {
    padding: 24px;
    width: 100%;
}

.changeMakerContainer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: right;
    padding-right: 100px;
    margin: 20px 0 0 20px;
    min-height: 300px;
    height: 300px;
}

.changeMakerImage {
    width: 100%;
}

@media (min-width: 768px) {
    .changeMakerContainer {
        width: 50%;
        margin: 20px auto 0 40px;
    }

    .changeMakerImage {
        width: 50%;
    }

    .columnLeft {
        width: 50%;
        margin: 0 0 0 auto;
    }
}

@media (min-width: 992px) {
    .changeMakerContainer {
    }
}