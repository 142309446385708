
.input {
    width: 100%;
    padding: 0.625rem 0.75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e6e6e;
    background-color: #fff;
    border: 0;
    margin: auto 0;
}

.inputHolder {
    margin-bottom: 24px;
    padding: 0 15px;
    width: 100%;
}

.inputGroup {
    display: inherit;
    align-items: end;
    width: 100%;
    padding: 3px;
    margin-left: auto;
    margin-right: 24px;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.375rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    border: 0;
    transition: box-shadow .15s ease;
}

.iconHolder {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 0.625rem 0.75rem;
}

.icon {
    display: flex;
    align-items: center;
    margin: auto 0;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.5;
    color: #adb5bd;
    text-align: center;
    transition: all .2s cubic-bezier(.68,-.55,.265,1.55);
}
.searchButton{ 
    align-items: center;
    margin: auto 6px;
    color: #004dff;
    background-color: #fff;
    border-color: #004dff;
    padding: 6px 10px;
    width: max-content;
    line-height: 1.5;
    border-radius: 0.375rem;
    font-size: .75rem;
    text-align: center;
}