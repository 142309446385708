.root {
    background: #FFF;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.container {
    margin: 0 auto;
    width: 83%;
}

.containerFinished{
    min-height: 524px;
}

.title {
    color: #000;
    font-size: 17px;
    font-weight: 500;
    margin: 20px 0 5px;
}

.subtitle {
    color: #54565A;
    font-size: 16px;
    font-weight: 600;
    margin: 24px 0 0;
}

.progressText {
    color: #54565A;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

.blueText {
    color: #004DFF;
}

.card {
    display: flex; 
    flex-wrap: wrap;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 8%), 0px 3px 4px 0px rgb(0 0 0 / 7%), 0px 1px 8px 0px rgb(0 0 0 / 7%);
    min-height: 100px;
    border-radius: 10px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;

    width: 100%;
}

.cardElevation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    border-radius: 10px;
    color: rgba(0, 0, 0, 0.87);

    min-height: 100px;

    overflow: hidden;

}

.imgHolder {
    margin: 0;
    width: 100%;
    height: 150px;
}

.cardContainer {
    font-family: 'Hind Madurai'!important;
    width: 100%;
}

.cardElevation .cardContainer {
    width: calc(100% - 75px)
}

.cardFooter {
    display: flex;
    margin: 25px 0 12px;
    width: 100%;
}

.cardHalf {
    width: 50%;
}

.cardDisabled {
    background: #F6F6F6;
    border-radius: 10px;
    min-height: 100px;
}

.cardPending {
    background: #F6F6F6;
    border-radius:5px;
    min-height: 100px;
}

.squareImage {
    width: 100%;
    height: calc(100% - 10px);
    object-fit: cover;
}

.titleChallenge {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    padding: 15px 30px 0;
}

.subHeader {
    color: #222;
    font-size: 16px;
    font-weight: 700;
    padding: 15px 30px 0;
    margin: 8px 0;
}

.titleLabel {
    color: #222;
    font-size: 16px;
    font-weight: 700;
    padding: 15px 30px 0;
}

.contentChallenge {
    color: #000;
    font-size: 14px;
    padding: 0 30px 0;
    white-space: pre-line;
}

.inProcessText {
    color: #004DFF;
    padding: 30px 0 0 30px;
    font-size: 16px;
    font-weight: 600;
}

.inPendingText {
    color: #66676a;
    padding: 30px 0 0 30px;
    font-size: 16px;
    font-weight: 600;
}

.finishedText {
    color: #004DFF;
    font-size: 14px;
    font-weight: 600;
    padding: 0 0 6px 10px;
}

.pendingText {
    color: #66676a;
    font-size: 14px;
    font-weight: 600;
    padding: 0 0 6px 10px;
}

.inProgressText {
    color: #004DFF;
    font-size: 14px;
    font-weight: 600;
    padding: 0 0 6px 10px;
}

.buttonNextContainer {
    display: flex;
    justify-content: flex-end;
    padding: 25px 30px 0 0;
    width: 50%;
}

.buttonContinue {
    background: #004DFF;
    border: 2px solid #004DFF;
    border-radius: 20px;
    color: #FFF;
    max-height: 35px;
    height: 35px;
    padding: 8px 16px;
    width: 95px;
}

.buttonContinue:hover {
    background: #fff;
    color: #004DFF;
    border: 2px solid #004DFF;
}

.challengesContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px 0;
}

.fourContainer {
    margin-bottom: 20px;
    padding: 0;
}

.fourContainerCenter {
    padding: 0;
    margin-top: 20px;
}

.fourContainerLast {
    padding: 0;
    margin-top: 20px;
}

.sixContainer {
    margin-bottom: 20px;
    padding: 0;
}

.sixContainerLast {
    margin-top: 20px;
    padding: 0;
}

.challengeTitle {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    max-height: 60px;
    height: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0;
}

.challengeSubtitle {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
    max-height: 44px;
    height: 44px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
}

.imageContainer {
    padding: 5px;
    height: 75px;
    width: 75px;
    margin: auto 0;
}

.imageContainerSlider {
    display: none;
    padding: 5px;
    height: 90px;
    width: 90px;
    margin: auto 0;
}

.rowContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33.33%;
}

.img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.imageDisabled {
    filter: grayscale(100%);
}

.buttonViewDocumentContainer {
    position: relative;
    flex-basis: 50%;
}

.buttonViewDocument {
    width: 130px;
    position: absolute;
    bottom: 10px;
    right: 20px;
}

.partnerContainer {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
    padding: 20px;
    min-height: 500px;
}

.partner {
    background: #fff;
    border-radius: 20px;
    min-height: 500px;
    border: 1px solid #b9b3aa;
    background-image: url("../../assets/08-fig.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
}

.partnerAvatarContainer {
    display: flex;
    justify-content: center;
}

.partnerAvatar {
    border-radius: 50%;
    margin-top: 20px;
    height: 130px;
    width: 130px;
}

.partnerName {
    color: #000;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    padding-top: 10px;
}

.partnerType {
    margin-top: -5px;
    font-size: 18px;
    color: #000;
    font-weight: 700;
    text-align: center;
}

.partnerBio {
    padding: 30px 40px;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}

.partnerContact {
    padding: 0 40px;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}

.social {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    margin: 10px 40px 20px;
}

.linkedIn {
    background: transparent;
}

.linkedInColor {
    color: #04689B;
    font-size: 30px;
}

.employability {
    background: #e8e7e8;
    border-radius: 20px;
    height: 100%;
    min-height: 500px;
    background-image: url("../../assets/rh_background.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.blackCover {
    display: flex; 
    flex-direction: column;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    min-height: 500px;
    background: url("../../assets/black_cover_card.svg");
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: flex-end;
}

.employabilityTitle {
    padding: 0 30px;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
}

.employabilitySubtitle {
    padding: 10px 30px;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 10px;
}

.noChallengesContainer {
    display: flex;
    min-height: 400px;
    margin: 45px 0;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0 0 32px;
    width: 100%;
    user-select: none;
}

.noChallengeSubtitle {
    text-align: center;
    color: #010101;
    margin-top: 4px;
    font-weight: 500;
    font-size: 24px;
    padding: 0 20px;
    width: 80%;
    margin: 0 auto;
}

.typeformContainer {
    display: flex;
    min-height: 400px;
    margin-top: 30px;
    background: #222222;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 0 32px;
    width: 100%;
    user-select: none;
}

.typeformDegree {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.lockIconContainer {
    text-align: center;
    color: #fff;
    margin-top: 40px;
    width: 24px;
}

.image {
    margin: 0 auto;
    width: 50%;
    height: auto;
}

.assesmentTitle {
    text-align: center;
    color: #fff;
    margin-top: 4px;
    font-size: 40px;
    font-weight: 700;
}

.assesmentSubtitle {
    text-align: center;
    color: #fff;
    margin-top: 4px;
    font-weight: 500;
    font-size: 16px;
    padding: 0 20px;
}

.lockIcon {
    font-size: 100px;
}

.twoCardContainer {
    display: flex;
    flex-flow: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 24px;
}

.twoCard {
    width: 100%;
}

.cardTextSlider {
    padding-left: 10px;
    width: 90%;
}

.challengeList {
    display: flex;
    flex-flow: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.showMore {
    border: 2px solid #0000AF;
    border-radius: 18.5px;
    background: transparent;
    color: #0000AF;

    padding: 8px 16px;
    margin: 24px auto 0;

    width: fit-content;
}

.hideLarge {
    display: flex;
    flex-direction: column;

    width: 100%;
}

.hideSmall {
    display: none;
    width: 100%;
}

.blocks {
    display: flex;
    flex-flow: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.employmentContent {
    display: flex; 
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
}

.employabilityText {
    flex-basis: 50%;

}

.smallCard {
    margin: 8px 0;
}

.disabled {
    cursor: not-allowed;
}

@media (min-width: 768px) {

    .smallCard {
        margin: 8px;
        width: calc(100% - 16px)!important;
    }

    .smallCard img {
        display: none;
    }

    .challengeList {
        display: none;
    }

    .cardContainer {
        font-family: 'Hind Madurai'!important;
        width: 66.67%;
    }
 
    .imgHolder {
        margin: 0;
        width: 33.33%;
        height: auto;
    }

    .hideSmall {
        display: block;
    }

    .hideLarge {
        display: none;
        width: 100%;
    }

    .container {
        width: 66.67%;
    }

    .title {
        margin-top: 30px;
    }

    .challengesContainer {
        margin: 30px 0 0 0;
    }

    .fourContainer{
        padding: 0 10px;
    }

    .fourContainerCenter {
        padding: 0 10px;
    }

    .fourContainerLast {
        padding: 0 0 0 20px;
    }

    .sixContainer {
        margin-bottom: 30px;
        padding-right: 20px;
    }

    .sixContainerLast {
        margin-top: 30px;
        padding-left: 20px;
    }

    .imageContainerSlider {
        display: block;
    }

    .partnerContainer {
        flex-grow: 0;
        max-width: 41.666667%;
        flex-basis: 41.666667%;
        padding: 20px;
        min-height: 500px;
    }

    .challengeBold {
        text-align: center;
        color: #010101;
        margin-top: 4px;
        font-weight: 700;
        font-size: 32px;
        padding: 0 20px;
        width: 60%;
        margin: 0 auto;
    }

    .noChallengeSubtitle {
        text-align: center;
        color: #010101;
        margin-top: 4px;
        font-weight: 500;
        padding: 0 20px;
        width: 60%;
        margin: 0 auto;
    }

    .assesmentTitle {
        font-size: 48px;
    }

    .assesmentSubtitle {
        margin: 0 auto;
        width: 50%;
        font-size: 20px;
    }

    .lockIcon {
        font-size: 140px;
    }
    
    .blocks {
        display: flex;
        flex-flow: row;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    .image {
        margin: auto;
        width: 20%;
        height: auto;
    }
}

@media (min-width: 1024px) {
    .smallCard img {
        display: block;
    }
}