.root {
    position: relative;
    background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important;
    flex-grow: 1;

    padding-bottom: 8rem;
    padding-top: 3rem;
}

.container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}

.card {
    position: relative;
    display: flex;
    flex-direction: row;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 1.5rem;
    padding: 1rem 1.5rem;
    width: calc(100% - 30px);
}

.card > div:first-child {
    padding-left: 0;
}

.card > div:last-child {
    padding-right: 0;
}

.cardItem {
    padding-right: 15px;
    padding-left: 15px;
}

.cardText {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.cardTitle {
    color: #32325d;
    font-size: 0.8125rem;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.5;
    text-transform: uppercase;
    margin-bottom: 0;
}

.cardNumber {
    color: #32325d;
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
}

.cardIcon {
    align-items: center;
    background-color: #f5365c !important;
    border-radius: 50%;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    color: #fff;
    display: inline-flex;
    justify-content: center;
    padding: 12px;
    text-align: center;
    transition: all 0.15s ease;
    width: 3rem;
    height: 3rem;
}

.cardIcon i {
    font-size: 1.25rem;
}

@media (min-width: 768px) {
    .root {
        padding-top: 8rem;
    }

    .container {
        flex-direction: row;
        padding-left: 39px;
        padding-right: 39px;
    }
}

@media (min-width: 992px) {
    .card {
        flex: 0 0 calc(50% - 30px);
        max-width: calc(50% - 30px);
    }
}

@media (min-width: 1366px) {
    .card {
        flex: 0 0 calc(25% - 30px);
        max-width: calc(25% - 30px);
    }
}