.root {
    background: #f7fafc;
    padding: 2.5rem 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.copyright {
    text-align: center!important;
    color: #525f7f;
    width: 100%;
}

.link {
    font-weight: 600 !important;
    margin-left: 0.25rem;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    justify-content: center;
}

.navLink {
    color: #6e6e6e!important;
}

.navLink:hover {
    color: #5e72e4!important;
}

@media (min-width: 1200px) {
    .root {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .copyright {
        text-align: left!important;
    }

    .nav {
        justify-content: flex-end;
    }
}