.card {
    display: flex;
    flex-wrap: nowrap;

    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    box-sizing: border-box;
    cursor: pointer;

    padding: 8px 16px;

    min-height: 100px;
    
    width: 100%;
    overflow: hidden;
}

.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    border: pointer;
}

.title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    font-weight: 700;
    
    text-overflow: ellipsis;
    margin: 0;
    overflow: hidden;
}

.subtitle {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    font-size: 14px;
    font-weight: 400;
    color: #666;
    
    max-height: 44px;
    height: 44px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.pendingText {
    color: #66676a;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    margin: 0;
}

.inProgressText,
.completeText {
    color: #004DFF;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    margin: 0;
}

.img {
    margin: auto 0;
    width: 75px;
    height: 75px;
    mask-image: url('../../assets/nao_mask.svg');
    mask-size: contain;
    mask-repeat: no-repeat;
}

.pending {
    filter: grayscale(100%);
}

/* .disabled {
    filter: grayscale(100%);
    cursor: not-allowed;
} */

@media (min-width: 768px) {
    .card {
        padding: 8px 16px;
    }
}