.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #F6F6F6;
    z-index: 10000;
}

.logo {
    width: 50%;
    margin: 0 auto;
    object-fit: contain;
    margin: 0 auto;
    margin-top: 10px;
}

.successful {
    font-size: 4vw;
    text-align: center;
    margin-top: 16vh;
    font-family: 'Baskerville BT';
    font-weight: 100;
    color: black;
    width: 50%;
    margin: auto;
}

.message {
    color: black;
    font-family: 'Hind Madurai';
    text-align: center;
    margin: 20px auto auto;
    width: 60%;
}

.link {
    color: blue;
    font-weight: 600;
    text-decoration: underline;
}