.root {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    background-color: #fff !important;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
}

.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
}

.menuButton {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.5);
    border: 1px solid transparent;
    border-radius: 0.375rem;
}

.menuIcon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: 50%/100% 100% no-repeat;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.brand {
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0.0625rem;
    margin-right: 0;
    font-size: 1.25rem;
    color: rgba(0, 0, 0, 0.9);
    line-height: inherit;
    white-space: nowrap;
}

.brandImg {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
    max-height: 3.5rem;
}

/* Profile button dropdown */

.dropdown {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    align-items: center;
}

.dropdownContainer {
    display: inline-block;
    position: relative;
}

.dropToggle {
    display: flex;
    color: #54565a;
    padding: 0.25rem 0.75rem;
    align-items: center;
}

.avatar {
    color: #fff;
    background-color: #adb5bd;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 0.875rem;
}

.avatarImg {
    width: 100%;
    border-radius: 50%;
}

.dropdownMenu {
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 12rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #525f7f;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.15);
    border-radius: 0.4375rem;
    box-shadow: 0 50px 100px rgb(50 50 93 / 10%), 0 15px 35px rgb(50 50 93 / 15%), 0 5px 15px rgb(0 0 0 / 10%);
}

.dropdownHeader {
    display: block;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 0.625rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #f6f9fc;
    white-space: nowrap;
}

.dropTitle {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5;
    color: #32325d;
    margin: 0;
}

.dropdownItem {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.icon {
    margin-right: 1rem;
    font-size: 1rem;
    vertical-align: -17%;
}

/* Collapse Menu */
.collapse {

}

.collapseHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.logo {
    height: 36px;
}

.collapseToggler {
    color: rgba(0, 0, 0, .5);
    border-color: transparent;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.375rem;
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding: 0;
}

.collapseToggler span {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    opacity: 1;
    background: #283448;
}

.collapseToggler span:nth-child(1) {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}

.collapseToggler span:nth-child(2) {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.collapseContainer {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    margin-left: -1rem;
    margin-right: -1rem;
    list-style: none;
}

.collapseLink {
    position: relative;
    padding: 0.625rem 0;
    color: #8F8F8F;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: .9rem;
}

.activeLink {
    color: #5e72e4;
}

/* add a before state to the collapse link when it is active */
.activeLink::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.25rem;
    bottom: 0.25rem;
    border-left: 2px solid #5e72e4;
}

.collapseLink i {
    position: relative;
    top: 2px;
    min-width: 2.25rem;
    font-size: .9375rem;
    line-height: 1.5rem;
    color: #5e72e4;
}


.collapseLink svg {
    color: #5e72e4;
}

.activeLink i {
    top: 0;
}

.show {
    display: block!important;
}

@media (max-width: 767px) {
    .collapse {
        display: none;
        width: calc(100% - 1.4rem);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1050;
        margin: 0.7rem;
        overflow-y: auto;
        height: auto !important;
        padding: 1.5rem;
        border-radius: 0.375rem;
        background: #FFF;
        box-shadow: 0 50px 100px rgb(50 50 93 / 10%), 0 15px 35px rgb(50 50 93 / 15%), 0 5px 15px rgb(0 0 0 / 10%);
        animation: show-navbar-collapse 0.2s ease forwards;
    }
}

@media (min-width: 768px) {
    .root {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        max-width: 250px;
        padding-left: 0;
        padding-right: 0;
        overflow-y: auto;
        overflow-x: hidden;
        left: 0;
        border-width: 0 1px 0 0;
    }

    .menuButton {
        display: none;
    }

    .brand {
        display: block;
        text-align: center;
        padding-bottom: 1rem;
        width: 100%;
    }

    .dropdown {
        display: none;
    }

    .collapse {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    
    .collapseHeader {
        display: none;
    }

    .collapseContainer {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .collapseContainer::before {
        content: "";
        display: block;
        margin: 1rem -1rem;
    }

    .collapseLink {
        padding: 0.65rem 1.5rem;
    }
}