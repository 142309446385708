.root {
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    border-radius: 0.375rem;
    transition: box-shadow .15s ease;
}

.editor {
  max-height: 450px;
  overflow-y: auto;
}

.toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    border: 0;
    border-radius: 0.375rem;
    color: #6e6e6e;
    transition: box-shadow 0.15s ease;
    padding: 0.625rem 0.75rem;
    width: 100%;
}

.h2 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
}

.paragraph {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 1rem;
}

.input {
    display: block;
    width: 100%;
    min-height: 100px;
    padding: 0.625rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0 0 0.375rem 0.375rem;
    border: 0;
}

.option {
    background-color: transparent;
    border: none;
    border-radius: 10px !important;
    color: #000;
    height: calc(1.5em + 1.25rem + 2px);
    width: max-content;
    min-width: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
}

.select {
    display: block;
    width: max-content;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e6e6e;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0 0 0.375rem 0.375rem;
    border: 0;
    margin-right: 0.5rem;
}

/* THEME */
.quote {
    margin: 0 0 10px 20px;
    font-size: 15px;
    color: #6e6e6e;
    border-left-color: #ced0d4;
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
}

.bold {
    font-weight: 700;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.divider {
    margin: 0 8px;
    border-left: 1px solid #D6DBDC;
}

.linkEditor {
    position: absolute;
    z-index: 100;
    top: -10000px;
    left: -10000px;
    margin-top: -6px;
    max-width: 300px;
    width: 100%;
    opacity: 0;
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: opacity 0.5s;
  }

.linkEditor .linkInput {
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 24px);
    margin: 8px 12px;
    padding: 8px 12px;
    border-radius: 10px;
    background-color: #fff;
    font-size: 16px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    position: relative;
    
  }

  .linkNoInput {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 24px);
    margin: 8px 12px;
    padding: 8px 12px;
    border-radius: 10px;
    background: #eee;
    font-size: 16px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    position: relative;
  }
  
  .linkEdit {
    color: #525f7f;
    width: fit-content;
    cursor: pointer;
  }
  
  .linkEditor .linkNoInput a {
    color: #525f7f;
    text-decoration: none;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
    text-overflow: ellipsis;
  }
  
  .linkEditor .linkInput a:hover {
    text-decoration: underline;
  }
  
  .linkEditor .button {
    width: 20px;
    height: 20px;
    display: inline-block;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 2px;
  }
  
  .linkEditor .button.hovered {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: #eee;
  }
  
  .linkEditor .button i,
  .actions i {
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: -0.25em;
  }