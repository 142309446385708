.background {
    background-image: url('../../../../assets/discord-backgroud.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    color: #fff;
    text-align: center;
    padding: 15px 0 0;
    height: 338px;
    width: 100%;
}

.icon {
    font-size: 96px;
}

.title {
    color: #fff;
    font-size: 26px;
    font-weight: 700;
    padding: 20px 0 0;
    margin: 0;
}

.subtitle {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    padding: 5px 0 0;
    margin: 0;
}

.discordContainer {
    display: flex;
    justify-content: center;
    margin: 30px 0 0;
}

.button {
    margin: 30px 0 0;
    width: 100px;
}

@media (min-width: 768px) {
    .icon {
        font-size: 144px;
    }
}