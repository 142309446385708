.root {
    display: flex;
    flex-direction: column;

    padding: 45px;
    padding-top: 15px;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin: 0;
}

.advancement {
    margin: 0;
}

.blueText {
    color: #004DFF;
    font-weight: 600;
}

.progress {
    display: flex;
    flex-direction: column;

    margin: 0;
}

.progressBarContainer {
    width: 100%;
}

.challengesContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    gap: 16px;

    padding: 10px 0 20px;
    margin: 20px 0 0;
}

.cardHolder {
    background: #f6f5f6;
    padding: 0;
    margin: 0;

    width: 100%;
}

.card {
    display: flex;
    flex-direction: row;
    background: #f6f5f6;
    border-radius: 10px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    color: rgba(0, 0, 0, 0.87);
    
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    min-height: 50px;
    overflow: hidden;
}

.cardInfo {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 10px;
    width: calc(100% - 75px)
}

.imageContainer {
    padding: 5px;
    height: 75px;
    width: 75px;
    margin: auto 0;
}

.img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.imageDisabled {
    filter: grayscale(100%);
}

@media (min-width: 768px) {
    .header {
        flex-direction: row;
        align-items: center;
    }

    .challengesContainer {
        flex-direction: row;

        padding: 10px 10px 20px;
    }

    .cardHolder {
        padding: 0 10px;
        width: calc(33.33% - 16px);
    }
}

@media (min-width: 1920px) {
    .cardHolder {
        width: calc(25% - 16px);
    }
}