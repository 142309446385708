.root {
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    overflow: auto;
    padding: 0 32px;
}

.progressBarContainer {
    margin: 20px 0 0;
    width: 100%;
}

.logout {
    font-weight: 600;

    margin: 60px 0;
}

.subtext {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;

    margin: 0 0 20px;
    width: 100%;
}