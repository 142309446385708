:root {

  /* Colors */
  --blueGray: #f6f9fc;
  --darkGray: #696a6d; 
  
  --active: #62e3d2;
  --inactiveBG: #f4f4f4;
  --inactiveText: #525f7f;

  /* Table patterns */
  --cell-padding-sides: 24px;
  --cell-padding-top-bottom: 16px;

  --h4-font-size: calc(1rem * 0.9375);

  /* Mobile Navbar */
  --mobile-navbar-height: 73px;

  /* Footer */
  --footer-height: 205px;
  --footer-height-sm: 226px;

  /* Viewports */
  --sm: 576px;
  --md: 768px;
  --lg: 992px;
  --xl: 1200px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25 !important;
}

.slick-prev:before, .slick-next:before {
  color: #000 !important;
  opacity: 1 !important;
}

.withoutHand {
  background-color: #F6F5F0;
  min-height: 400px;
}

h6 {
  font-size: 20px;
  font-weight: 500;
}

/* Input of type date */
textarea {
  height: auto!important;
} 
