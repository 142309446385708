.root {
    display: flex;
    flex-direction: row;

    background: #F6F6F6;
    min-height: 150px;

    padding: 25px 15px;
    position: relative;
}

.info {
    margin: auto 0;
    height: max-content;
    padding: 10px;
    width: 100%;
}

.role {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.subtext {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;

    margin: 0 0 20px;
    width: 100%;
}

.logout {
    margin: 0;
}

@media (min-width: 768px) {
    .logout {
        margin: 0 auto;
    }
}