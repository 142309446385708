.root {
    display: inline-block;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.625rem 1.25rem;
    line-height: 1.5;
    border-radius: 0.375rem;
    position: relative;
    text-transform: none;
    transition: all .15s ease;
    letter-spacing: .025em;
    font-size: .875rem;
    will-change: transform;

    color: #fff;
    background-color: #004dff;
    border-color: #004dff;
}

.root:hover {
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    transform: translateY(-1px);
}

.light {
    color: #212529;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.disabled {
    background: #EBEBE4!important;
    border: 2px solid #EBEBE4!important;
    cursor: not-allowed;
}

.icon {
    font-size: 0.85rem;
    width: 2rem;
    height: 2rem;
    padding: 0;
    line-height: 1rem;
    border-radius: 50% !important;
}

.icon:not(:last-child) {
    margin-right: 8px;
}