.root {
    padding: 0 15px;
    margin-top: -10rem;
}

.tableContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
    transition: all 0.15s ease;
}

.tableHeader {
    display: flex;
    flex-direction: column;
    justify-content: left;

    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
    padding: 1.25rem 1.5rem;
    margin-bottom: 0;
    background-color: #fff;
}

.title {
    margin: 6px 0;
}

.table {
    align-items: center;

    width: 100%;
    overflow-x: auto;
}

.thead, tbody {
    width: 100%;
}

.titleCol {
    display: flex;
    flex-direction: column;

    overflow: hidden;
    padding: 13px var(--cell-padding-sides);
    margin: 0;
    height: 64px;
    width: 300px;
}

.tr {
    max-height: 64px;
    border-bottom: 1px solid rgba(0,0,0,.05)
}

.th {
    background-color: var(--blueGray);
    color: var(--darkGray);

    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: .65rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #e9ecef;
}

.tdCheck{ 
    align-items: center;
    padding: 10px 50px;
    width: 120px;
}
.th, .td {
    padding: var(--cell-padding-top-bottom) var(--cell-padding-sides);
    max-height: 64px;
}

.name {
    font-size: .8125rem;
    font-weight: 600;
}

.description {
    color: #6E6E6E;
    font-size: .8125rem;

    padding: 0;
    margin: 0;
    overflow: hidden;   
}

.actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.addButton {
    color: #fff;
    background-color: #004dff;
    border-color: #004dff;
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    border-radius: 0.375rem;
    font-size: .75rem;
}

.edit {
    color: #fff;
    background-color: #11cdef;
    border-color: #11cdef;
}

.delete {
    color: #fff;
    background-color: #f5365c;
    border-color: #f5365c;
}

.tableFooter {
    padding: 1.5rem 1.5rem;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
}

.modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modalContent {
    border-radius: 1rem;
    background-color: #beffeb;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }


.form {
    display: grid;
    place-content: center;
    min-height: 100vh;
  }
  
  .pagination {
    display: flex;
    padding-left: 0;
    margin-top: 1rem;
    list-style: none;
    border-radius: 0.375rem;
    justify-content: flex-end !important;
    gap: 24px;
}

.delete {
    color: #fff;
    background-color: #f5365c;
    border-color: #f5365c;
}

  input[type="checkbox"] {
    accent-color: #004dff;    
    margin: 0;
    font: inherit;
    width: 1.2rem;
    height: 1.2rem;
    border: 0.15em solid;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }
  
  input[type="checkbox"]::before {
    content: "";
    width: 0.4em;
    height: 0.4em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: #fff;
  }
  
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  
  input[type="checkbox"]:focus {
    outline-offset: max(2px, 0.15em);
  }

  .input {
    width: 100%;
    padding: 0.625rem 0.75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e6e6e;
    background-color: #fff;
    border: 0;
    margin: auto 0;
}

.inputHolder {
    margin-bottom: 24px;
    padding: 0 15px;
    width: 100%;
}

.inputGroup {
    display: inherit;
    align-items: end;
    width: 100%;
    padding: 3px;
    margin-left: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.375rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    border: 0;
    transition: box-shadow .15s ease;
}

.iconHolder {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 0.625rem 0.75rem;
}

.icon {
    display: flex;
    align-items: center;
    margin: auto 0;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.5;
    color: #adb5bd;
    text-align: center;
    transition: all .2s cubic-bezier(.68,-.55,.265,1.55);
}
.searchButton{ 
    align-items: center;
    margin: auto 6px;
    color: #fff;
    background-color: #004dff;
    border-color: #004dff;
    padding: 12px 10px;
    width: max-content;
    line-height: 1.5;
    border-radius: 0.375rem;
    font-size: .75rem;
    text-align: center;
}

.noData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}   

.noData p {
    color: #6e6e6e;
    margin: 0;
}

.roleCount { 
    text-align: center;
    width: 25%;
}

@media (min-width: 768px) {
    .root {
        padding: 39px;
    }

    .titleCol {
        width: 100%;
    }

    .inputGroup {
        width: 60%;
    }
}

@media (min-width: 992px) {
    .root {
        padding: 0 39px;
        margin-top: -100px;
    }

    .tableHeader {
        flex-direction: row;
        justify-content: space-between;
    }

    .inputGroup {
        width: 100%;
    }
}