.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.description,
.row {
    display: flex; 
    flex-direction: column;
    
    margin-bottom: 12px;
    width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.popup {
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
}

.checkboxContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-left: 4px;
}

.checkbox {
    accent-color: #2ED9C3;
    margin: 4px 0 0;
    height: fit-content;
}

.checkboxLabel {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    margin: 0;
}

@media (min-width: 768px) {
    .form {
        min-width: 542px;
    }

    .row {
        flex-direction: row;
        gap: 48px;
    }

    .column {
        flex-direction: column;
        width: 50%;
    }
}