.root {
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #fff;
}

.terms {
    color: #9e9e9e;
    font-weight: 600;
    font-size: 0.8rem;
    text-align: start;
}

.copyright {
    font-weight: 600;
    font-size: 0.85rem;
    text-align: start;
}

.footer {
    position: absolute;
    bottom: 0;
}

.socialContainer {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 192px;
    z-index: 9999;
}

.socialBtn {
    height: 48px;
    width: 56px;
    background-color: #f6f6f6;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 300ms;
}

.socialBtn:hover {
    background-color: #c5c5c5;
    transition: 300ms;
}

.btnContainer {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    width: 120px;
    padding-bottom: 24px;
}

.linkContainer {
    height: auto;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.link {
    color: #696A6D;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 10px 0;
    font-family: 'Hind Madurai';
    transition: 300ms;
}

@media (min-width: 768px) {
    .root {
        padding-left: 24px;
        padding-right: 24px;
    }

    .terms {
        font-size: 0.95rem;
        text-align: start;
    }

    .copyright {
        font-size: 0.95rem;
        text-align: start;
    }

    .socialContainer {
        height: 96px;
        flex-flow: row;
        justify-content: space-between;
    }

    .btnContainer {
        padding-bottom: 0;
    }

    .link {
        font-size: 0.85rem;
    }
}

@media (min-width: 992px) {
    .terms {
        font-size: 1rem;
        text-align: start;
    }

    .copyright {
        font-size: 1rem;
        text-align: start;
    }
}