.root {
    padding: 0 15px;
    margin-top: -10rem;
}

.tableContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
    transition: all 0.15s ease;
}

.darkTableContainer {
    background-color: #f7fafc!important;
}

.tableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
    padding: 1.25rem 1.5rem;
    margin-bottom: 0;
    background-color: #fff;
}

.title {
    margin: 0;
}

.form {
    display: flex;
    flex-direction: column;
    padding: 24px;
}

.subtitle {
    color: #696a6d;
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    padding-top: 0.25rem;
    padding: 0 15px 0.25rem;
    margin-bottom: 1.5rem;  
}

.inputs {
    display: flex;
    flex-direction: column;
}

.inputHolder {
    margin-bottom: 24px;
    padding: 0 15px;
    width: 100%;
}

.input {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e6e6e;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.375rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    border: 0;
    transition: box-shadow .15s ease;
}

.input:valid {
    color: #000;
}

.input[readOnly] {
    background-color: #e9ecef;
    pointer-events: none;
    opacity: 1;
}

.textarea {
    height: auto;
}

.datePickerContainer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    border: 0;
    border-radius: 0.375rem;
    transition: box-shadow 0.15s ease;
}

.inputGroup {
    position: relative;
    display: flex;
    flex-grow: 1;
}

.iconHolder {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 0.625rem 0.75rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0.375rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    transition: box-shadow .15s ease;
}

.icon {
    display: flex;
    align-items: center;
    margin: auto 0;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #adb5bd;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 0;
    border-radius: 0.375rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
    transition: all .2s cubic-bezier(.68,-.55,.265,1.55);
}

.iconInput {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
}

.name {
    font-size: .8125rem;
    font-weight: 600;
}

.actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.addButton {
    color: #fff;
    background-color: #004dff;
    border-color: #004dff;
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    border-radius: 0.375rem;
    font-size: .75rem;
}

.edit {
    color: #fff;
    background-color: #11cdef;
    border-color: #11cdef;
}

.delete {
    color: #fff;
    background-color: #f5365c;
    border-color: #f5365c;
}

.tableFooter {
    padding: 1.5rem 1.5rem;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.375rem;
    justify-content: flex-end !important;
    gap: 24px;
}

.sprints {
    padding: 0 15px;
}

.feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}

.hidden {
    display: none;
}

@media (min-width: 992px) {
    .root {
        padding: 0 39px;
        margin-top: -100px;
    }

    .form {
        display: flex;
        flex-direction: column;
        padding: 24px 33px;
    }

    .inputs {
        flex-direction: row;
    }
}