/* ----------------------------------------------
 * Generated by Animista on 2023-3-15 16:32:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-ver-left
 * animation puff-in-center
 * ----------------------------------------
 */
@keyframes wobble {
    0%,
    100% {
        -webkit-transform: translateY(0) rotate(0);
        transform: translateY(0) rotate(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    33% {
        -webkit-transform: translateY(-12px) rotate(-24deg);
        transform: translateY(-12px) rotate(-24deg);
    }

    66% {
        -webkit-transform: translateY(8px) rotate(6deg);
        transform: translateY(8px) rotate(6deg);
    }
}

@keyframes wobbleBack {
    0%,
    100% {
        -webkit-transform: translateY(0) rotate(0);
        transform: translateY(0) rotate(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    66% {
        -webkit-transform: translateY(-8px) rotate(-6deg);
        transform: translateY(-8px) rotate(-6deg);
    }

    33% {
        -webkit-transform: translateY(12px) rotate(24deg);
        transform: translateY(12px) rotate(24deg);
    }
}

@-webkit-keyframes puff {
    0% {
        -webkit-transform: scale(2);
                transform: scale(2);
        -webkit-filter: blur(4px);
                filter: blur(4px);
        opacity: 0;
      }
      100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        -webkit-filter: blur(0px);
                filter: blur(0px);
        opacity: 1;
      }
}

@keyframes puff {
    0% {
        -webkit-transform: scale(2);
                transform: scale(2);
        -webkit-filter: blur(4px);
                filter: blur(4px);
        opacity: 0;
      }
      100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        -webkit-filter: blur(0px);
                filter: blur(0px);
        opacity: 1;
      }
    }
    @keyframes puff-in-center {
      0% {
        -webkit-transform: scale(2);
                transform: scale(2);
        -webkit-filter: blur(4px);
                filter: blur(4px);
        opacity: 0;
      }
      100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        -webkit-filter: blur(0px);
                filter: blur(0px);
        opacity: 1;
      }
}


.root {
    display: flex;
    flex-direction: column;
    background: #F6F6F6;
    border-top: 1px solid #afafaf;
    width: 100%;
}

.content {
    display: flex;
    width: 100%;
    -webkit-animation: puff 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
    animation: puff 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

.textNext {
    font-size: 16px;
    font-weight: 500;
    padding: 30px 40px 0;
}

.textNow {
    text-align: center;
    padding-bottom: 30px;
    font-size: 16px;
    font-weight: 500;
    padding: 24px 40px;
    margin: 0;
    width: 100%;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    margin: 0 0 10px;
}

.actionButton {
    display: flex;
    flex-direction: row;
    background: transparent;
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    margin: 12px;
    border: none;
    color: #0000AF;
    text-decoration: underline;
}

.icon {
    filter: invert(13%) sepia(58%) saturate(6474%) hue-rotate(241deg) brightness(73%) contrast(140%);
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.wobble {
    -webkit-animation: wobble 0.8s both;
    animation: wobble 0.8s both;
}

.wobbleBack {
    -webkit-animation: wobbleBack 0.8s both;
    animation: wobbleBack 0.8s both;
}

@media (min-width: 768px) {
    .root {
        flex-direction: row;
    }

    .textNext {
        padding: 24px 16px;
        margin: 0;
    }

    .buttonsContainer {
        margin: 0 0 0;
    }
}