.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.modalContent {
    background-color: #fff;
    border-radius: 32px;
    padding: 24px;
    width: 100%;
    max-width: 600px;
    position: relative;
}

@media (min-width: 768px) {
    .modalContent {
        padding: 32px;
    }
}