.grid {
   display: flex;
   flex-grow: 1;
   padding: 0;
   background-color: #fff; 
}

.content {
    flex-grow: 1;
}

.hideSmall {
    display: none;
}

@media (min-width: 768px) {
    .hideSmall {
        display: block;
    }

    .hideLarge {
        display: none;
    }
}