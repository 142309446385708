.offset {
    min-height: 85px;
}

.logo {
    max-height: 65px;
    margin-left: 0;   
}

.topContainer {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    min-height: 85px;
    width: 100%;
}

.tabsContainer {
    display: none;
}

.enterContainer {
    position: absolute;
    right: 20px;
}

.buttonEnter {
    padding-left: 10px;
}

.large {
    margin-left: 10px;
    background-color: #fff;
    width: 48px;
    height: 48px;
}

.horizontalList {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    position: relative;
    list-style: none;
}

.buttonNav {
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    width: max-content;
    padding: 0;
    font-weight: 700;
    color: #323132;
    border-radius: 50%;
}

.buttonNav:last-child {
    padding-right: 16px;
    margin-left: 10px;
}

.large img {
    color: transparent;
    width: 100%;
    height: 100%;
    object-fit: cover;
    text-align: center;
    text-indent: 10000px;
    border-radius: 50%;
}

.buttonContent {
    display: none;
}

.rightIcon {
    display: none;
}

.appBar {
    display: flex;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    top: 0;
    left: auto;
    right: 0;
    position: fixed;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 7%), 0px 1px 1px 0px rgb(0 0 0 / 2%), 0px 1px 3px 0px rgb(0 0 0 / 4%);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 100%;
    z-index: 1201;
}

.profileName {
    display: none;
}

.menuButton {
    position: absolute;
    left: 30px;
    margin-left: -12px;
    color: #1a1a1a!important;
    background-color: transparent!important;
    border: none!important;
}

@media (min-width: 768px) {
    .logo {
        margin-left: 12px;
    }

    .topContainer {
        justify-content: space-between;
        padding-left: 24px;
        padding-right: 24px;
    }

    .tabsContainer {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-right: 0;
    }

    .large {    
        width: 56px;
        height: 56px;
    }

    .buttonContent {
        width: 100%;
        display: flex;
        position: relative;
        box-sizing: border-box;
        text-align: left;
        align-items: center;
        padding-top: 8px;
        padding-bottom: 8px;
        justify-content: flex-start;
        text-decoration: none;
    }

    .menuButton {
        display: none; 
    }

    .profileName {
        display: flex;
        flex: 1 1 auto;
        min-width: 0;
        margin-top: 4px;
        margin-bottom: 4px;
        font-size: 18px;
        font-family: "Hind Madurai, sans-serif";
    }

    .rightIcon {
        display: flex;
        justify-content: right;
        min-width: 0;
    }
}

@media (min-width: 768px) {
    .logo {
        margin-left: 20px;
    }

    .enterContainer {
        position: initial;
    }
}