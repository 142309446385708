.root {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    height: 100%;
}

.container {
    padding: 32px 0;
    flex-grow: 1;
}

.description {
    color: #000;
    padding: 40px 50px;
    font-size: 16px;
    white-space: pre-line;
    margin: 0;
}

.learnMoreTitle {
    color: #000;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.theChallengeTitle {
    padding: 50px 30px 10px;
    color: #000;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin: 0;
}

.theChallengeDescription {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    white-space: pre-line;
    text-align: center;
    padding: 0 20px 10px;
    margin: 0;
}

.cardVideoContainer {
    padding: 20px 30px;
    width: 100%;
}

.cardVideo {
    background: transparent;
    overflow: hidden;
}

.media {
    border-radius: 15px;
}

.continueChallegeText {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin-top: 30px;
}

.continueButtonContainer {
    margin-top: 5px;
    display: flex;
    justify-content: center;
}

.imageContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.button {
    width: max-content;
    margin-top: 10px;
}

.info {
    display: flex;
    flex-direction: column;

    width: 100%;
}

.theChallengeContainer {
    display: flex;
    flex-direction: column;
    background-image: url('../../../../assets/linea.png');
    background-repeat: no-repeat;
    background-position: top 5px center;
    background-size: 500px 700px;

    margin: 10px 0;
    width: 100%;
}

.toolContainer {
    margin-bottom: 30px;
}

.altButton {
    width: max-content;
    margin: 0 auto 30px;
}

.researchContainer {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
}

.developmentContainer {
    display: flex;
    flex-direction: column;
    margin: 0 0 30px;
    width: 100%;
}

.developmentItem {
    display: flex;
    flex-direction: column;
    margin: 0 0 30px;
    width: 100%;
}

.developmentImage {
    width: auto;
    height: 150px;
}

.items {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 0 0 10px;
    width: 100%;
}

.navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #F6F6F6;
    padding: 30px 0;
    width: 100%;
}

.navButton {
    margin-right: 30px;
}

.nextButton {
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    border: none;
    background: url("../../../../assets/button-icon_active@2x.png")!important;
    background-size: cover!important;
    color: transparent!important;
}

.navButton:last-child {
    margin-right: 0;
}

.nextButton:hover {
    background: url("../../../../assets/button-icon_hover@2x.png")!important;
    background-size: cover!important;
}

.backButton {
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    border: none;
    background: url("../../../../assets/button-icon_active2x-back.png")!important;
    background-size: cover!important;
    color: transparent!important;
}

.backButton:hover {
    background: url("../../../../assets/button-icon_hover2x-back.png")!important;
    background-size: cover!important;
}

.actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 24px 0;
}

.actionButton {
    background: #004DFF;
    padding: 8px 24px;
    border-radius: 25px;
    color: #fff;
    font-size: 16px;
    cursor:pointer
}

.actionButton:hover {
    background: #fff;
    color: #0039CB;
    border: 1px solid #0039CB;
}

@media (min-width: 768px) {
    .theChallengeContainer {
        display: flex;
        flex-direction: row;
    
        width: 100%;
    }

    .info {
        width: 66.67%;
    }

    .theChallengeTitle {
        text-align: left;
    }

    .theChallengeDescription {
        text-align: left;
        padding: 0 30px 40px;
    }
     
    .cardVideoContainer {
        padding: 60px 30px 20px;
        width: 33.33%;
    }

    .altButton {
        width: max-content;
        margin: 0 30px 30px;
    }

    .developmentItem {
        width: 50%;
    }
    
    .actions {
        flex-direction: row;
    }
}