.root ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

.root ol li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
}

.root ol li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
}

.root li ol li {
    margin: 0;
}

.article {
    max-width: calc(650px + 64px);
    padding: 0 32px;
    margin: 2rem auto;
}

.title {
    color: #0000AF;
    font-family: 'Hind Madurai';
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
}

.subtitle {
    color: #0000AF;
    font-family: 'Hind Madurai';
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
}

.text {
    color: #222222;
    font-family: 'Hind Madurai';
    font-size: 1rem;
    font-weight: 300;
    text-align: justify;
}