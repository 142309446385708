.root{
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin: auto;
    
    background: #0000af;
    border: 2px solid #0000af;
    border-radius: 20px;
    color: #FFF;

    max-height: 35px;
    height: 35px;
    padding: 8px 16px;
}

.isicon {
    border-radius: 50%;
    max-height: none;
    height: auto;
    padding: 12px;
}

.light {
    background-color: transparent;
    text-align: center;
    border: 2px solid #004DFF;
    color: #004DFF !important;
    font-size: 14px;
    font-weight: 600;
}

.isDropOut {
    background-color: #ff7589;
    text-align: center;
    border: 2px solid #ff7589;
    color: #fff !important;
    font-size: 14px;
    font-weight: 600;
}


.discord {
    background-color: #fff;
    border: none;
    box-shadow: 2px 2px 12px -3px #000000;
    color: #2727b9 !important;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
}

.discord:hover {
    transform: scale(1.05);
}

.disabled {
    background: #EBEBE4;
    border: 2px solid #EBEBE4;
    cursor: not-allowed;
}

.container {
}

.text {
    display: flex; 
    justify-content: center;
    align-items: baseline;

    font-weight: 500;
    font-size: 14px;
    height: 18px;
    margin: auto;
}