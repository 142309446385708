.row {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.name {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profileSection {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.socialSection {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;

     padding: 20px 0 0;
     width: 100%;
}

.link {
    color: #004DFF;
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}

.banner { 
    width: 100%;
    /* background-color: #f6f6f6 ; */
    background-color: #222222;
    padding-top: 32px;
    padding-left: 70px;
    padding-bottom: 32px;
    padding-right: 50px;
}

.textBanner { 
    font-weight: 500;
    margin-right:250px ;
    text-align: left;
    font-size: 1rem;
    letter-spacing: 0.4px;
    color: #fff;
    margin-bottom: 1.5rem;
}

.titleBanner{ 
    font-size: 1rem;
    color: #fff;
    margin-bottom: 1rem;
}

.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    padding: 32px 48px;
    width: 100%;
}

.image {
    width: 150px;
}

.image img {
    width: 100%;
}

.close {
    position: absolute;
    top: 0;
    right: 0;

    padding: 16px;
    cursor: pointer;
}

@media (min-width: 300px) and (max-width: 768px) {
    .textBanner{ 
        margin-right:24px ;
    }

    .banner{ 
        padding-right: 20px;
    }
}

@media (min-width: 768px) {
    .row {
        flex-direction: row;
    }

    .name {
        width: 33.34%;
    }

    .column {
        flex-direction: column;
        width: 16.66%;
    }

    .socialSection {
        padding: 20px 30px 0;
   }
}
