.root {
    padding: 0 15px;
    margin-top: -10rem;
}

.confirmButton {
    background-color: #f5365c;
    border: 1px solid #f5365c;
    border-radius: 0.25em;
    color: #fff;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
}

.cancelButton {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 6px;
    color: #000;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
}

.warningIcon {
    width: 72px!important;
    height: 72px!important;
    border: none!important;
    border-radius: 0!important;
    background-image: url('../../assets/warning.png');
}

.tableContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
    transition: all 0.15s ease;
}

.tableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
    padding: 1.25rem 1.5rem;
    margin-bottom: 0;
    background-color: #fff;
}

.title {
    margin: 0;
}

.tableHolder {
    align-items: center;
    width: 100%;
    overflow-x: auto;
}

.table {
    align-items: center;
}

.thead, .tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tr {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-height: 64px;
    border-bottom: 1px solid rgba(0,0,0,.05)
}

.th {
    display: flex;
    background-color: var(--blueGray);
    border-bottom: 1px solid #e9ecef;
    color: var(--darkGray);

    font-size: .65rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0;
    max-height: 64px;
    width: 25%;
    white-space: nowrap;
}

.titleTh {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 13px var(--cell-padding-sides);
}

.titleCol {
    display: flex;
    flex-direction: column;

    overflow: hidden;
    padding: 13px var(--cell-padding-sides);
    margin: 0;
    height: 64px;
    width: 50%;
}


.th.actionButtons {
    width: 120px;
}

.th p {
    color: var(--darkGray);
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    
    margin: auto;
    width: 100%;
    white-space: nowrap;
}

.td {
    max-height: 64px;
}

.name {
    font-size: .8125rem;
    font-weight: 600;
}

.description {
    color: #6E6E6E;
    font-size: .8125rem;

    padding: 0;
    margin: 0;
    overflow: hidden;   
    text-overflow: ellipsis;
    white-space: nowrap;
}

.roleCount { 
    display: flex;
    text-align: center;
    width: 25%;
}

.roleCount p {
    text-align: center;
    margin: auto 0;
    width: 100%;
}

.actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 25%;
}

.addButton {
    color: #fff;
    background-color: #004dff;
    border-color: #004dff;
    padding: 12px 10px;
    line-height: 1.5;
    border-radius: 0.375rem;
    font-size: .75rem;
}

.icon { 
    margin: auto 0;
    min-width: 32px;
    width: 32px;
    height: 32px;
}

.edit {
    color: #fff;
    background-color: #11cdef;
    border-color: #11cdef;
}

.delete {
    color: #fff;
    background-color: #f5365c;
    border-color: #f5365c;
}

.copy {
    color: #fff;
    background-color: #5973EC;
    border-color: #5973EC;
}

.tableFooter {
    padding: 1.5rem 1.5rem;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
}

.duplicateConfirmButton {
    background-color: #fff;
    border: 1px solid #004dff;
    border-radius: 0.25em;
    color: #004dff;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
}

.duplicateCancelButton {
    background-color: #004dff;
    border: 1px solid #004dff;
    border-radius: 6px;
    color: #fff;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 96px auto auto;
    max-width: none;
    width: 45%;
}

.modalContent {
    border-radius: 25px;
    padding: 12px;
}

.modalBody {
    position: relative;
    flex: 1 1 auto;
    padding: 0 1.5rem;
}

.modalTitle {
    margin-bottom: 0;
    line-height: 1.1;
    margin-bottom: 16px;
}

.modalText {
    text-align: left;
}

.modalText p {
    margin-bottom: 2px;
}

.modalText b {
    font-weight: 700;
}

.confirm {
    background-color: #004dff;
    border: none;
    box-shadow: none;
}

.cancelar {
    border: none;
    box-shadow: none;
}

.noData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}   

.noData p {
    color: #6e6e6e;
    margin: 0;
}

@media (min-width: 768px) {
    .root {
        padding: 39px;
    }
}

@media (min-width: 992px) {
    .root {
        padding: 0 39px;
        margin-top: -100px;
    }

    .th {
        width: 15%;
    }

    .titleTh {
        width: 55%;
    }

    .titleCol {
        width: 55%;
    }

    .roleCount {
        width: 15%;
    }

    .actionButtons,
    .th.actionButtons {
        width: 15%;
    }
}