.root {
    background: #f6f6f6;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

/* General */
.container {
    margin: 0 auto;
    width: 83%;
}

/* Introduction */

.title {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    margin: 20px 0 5px;
}

.subtitle {
    color: #54565A;
    font-size: 16px;
    font-weight: 600;
    margin: 24px 0 0;
}

.descriptionText {
    color: #54565A;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

.descriptionText:first-of-type {
    font-weight: 700;
}
.descriptionText:last-of-type {
    margin-bottom: calc(10px + 1rem);
}

/* Columns */
.row {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 20px;
}

/* Iframe */
.iframe {
    width: 100%;
    height: fit-content;
    aspect-ratio: 16/9;
}

/* Steps */
.steps {
    width: 100%;
    margin-bottom: 0;
    list-style: none;
    padding: 0;
    max-height: 100%;
}

.step {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 1px -2px rgb(0 0 0/20%), 0 2px 2px 0 rgb(0 0 0/14%), 0 1px 5px 0 rgb(0 0 0/12%);
    box-sizing: border-box;
    padding: 8px 16px;
    /* min-height: 100px; */
    width: 100%;
    overflow: hidden;
    margin-bottom: 1rem;
}

.step:last-child {
    margin-bottom: 0;
}

.stepTitle {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 0.5rem;
}

.stepContent {
    font-size: 14px;
    font-weight: 400;
    color: #666;
    margin-bottom: 0.5rem;
}

.stepNote {
    font-size: 12px;
}

/* Button */
.buttonNextContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.buttonContinue {
    background: #0000af;
    border: 2px solid #0000af;
    border-radius: 20px;
    color: #FFF;
    max-height: 35px;
    height: 35px;
    padding: 8px 16px;
    width: fit-content;
}

.buttonContinue:hover {
    background: #fff;
    color: #0000af;
    border: 2px solid #0000af;
}

/* Misc */
.blueText {
    color: #004cff;
}

.disabled {
    cursor: not-allowed;
}

@media (min-width: 768px) {

    .container {
        width: 66.67%;
    }

    .title {
        margin-top: 30px;
    }

    .row {
        flex-direction: row;
        margin-bottom: 30px;
    }
    
    /* Iframe */
    .iframe {
        width: 70%;
    }
    
    /* Steps */
    .steps {
        width: 30%;
        overflow-y: scroll;
        scrollbar-gutter: stable;
        scrollbar-width: thin;
        scrollbar-color: #666 #f6f6f6;
    }

}

/* @media (min-width: 1024px) {
    .smallCard img {
        display: block;
    }
} */