.root {
    padding: 0 15px;
    margin-top: -10rem;
}

.tableContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
    transition: all 0.15s ease;
}

.darkTableContainer {
    background-color: #f7fafc!important;
}

.tableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
    padding: 1.25rem 1.5rem;
    margin-bottom: 0;
    background-color: #fff;
}

.title {
    margin: 0;
}

.form {
    display: flex;
    flex-direction: column;
    padding: 24px;
}

.inputs {
    display: flex;
    flex-direction: row;
}

.inputHolder {
    margin-bottom: 24px;
    padding: 0 15px;
    width: 100%;
}

.input {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e6e6e;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.375rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    border: 0;
    transition: box-shadow .15s ease;
}

.users {
    display: flex;
    flex-direction: column;
}

.user {
    text-align: center;
    padding: 0 15px;
    margin-bottom: 24px !important;
    width: 100%;
}

.tableFooter {
    padding: 1.5rem 1.5rem;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.375rem;
    justify-content: flex-end !important;
    gap: 24px;
}

.delete {
    color: #fff;
    background-color: #f5365c;
    border-color: #f5365c;
}

.feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}

@media (min-width: 992px) {
    .root {
        padding: 0 39px;
        margin-top: -100px;
    }

    .form {
        display: flex;
        flex-direction: column;
        padding: 24px 33px;
    }

    .users {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .user {
        width: 16.66%;
    }
}