.root {
    position: relative;
    padding: 0 15px;
    margin-top: -10rem;
}

.confirmButton {
    background-color: #f5365c;
    border: 1px solid #f5365c;
    border-radius: 0.25em;
    color: #fff;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
}

.cancelButton {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 6px;
    color: #000;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
}

.warningIcon {
    width: 72px!important;
    height: 72px!important;
    border: none!important;
    border-radius: 0!important;
    background-image: url('../../../../assets/warning.png');
}

.tableContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
    transition: all 0.15s ease;
    margin-bottom: 48px;
}

.darkTableContainer {
    background-color: #f7fafc!important;
}

.tableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
    padding: 1.25rem 1.5rem;
    margin-bottom: 0;
    background-color: #fff;
}

.title {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.small {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e6e6e;
}

.form {
    display: flex;
    flex-direction: column;
    padding: 24px;
}

.inputs {
    display: flex;
    flex-direction: row;
}

.inputHolder {
    margin-bottom: 24px;
    padding: 0 15px;
    width: 100%;
}

.input {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e6e6e;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.375rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    border: 0;
    transition: box-shadow .15s ease;
}

.input[disabled] {
    background-color: #e9ecef;
    opacity: 1;
}

.tableFooter {
    padding: 1.5rem 1.5rem;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
    width: 100%;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.375rem;
    justify-content: flex-end !important;
    gap: 24px;
}

.delete {
    color: #fff;
    background-color: #f5365c;
    border-color: #f5365c;
}

.feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}

.tableHolder {
    align-items: center;
    width: 100%;
    overflow-x: auto;
}

.table {
    align-items: center;
}

.thead, .tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.titleTh {
    display: flex;
    flex-direction: column;
    width: 50%!important;
    padding: 13px var(--cell-padding-sides);
}

.titleCol {
    display: flex;
    flex-direction: column;

    overflow: hidden;
    padding: 13px var(--cell-padding-sides);
    margin: 0;
    height: 64px;
    width: 50%;
}

.tr {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-height: 64px;
    border-bottom: 1px solid rgba(0,0,0,.05)
}

.th {
    background-color: var(--blueGray);
    color: var(--darkGray);

    padding: 8px 16px;
    font-size: .65rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #e9ecef;
    width: 25%;
    white-space: nowrap;
    max-height: 64px;
}

.th p {
    color: var(--darkGray);
    font-size: .65rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    white-space: nowrap;
    margin: auto;
    width: 100%;
}

.td {
    padding: 8px 16px;
    max-height: 64px;
    width: 25%;
}

.name {
    font-size: .8125rem;
    font-weight: 600;
}

.description {
    color: #6E6E6E;
    font-size: .8125rem;

    padding: 0;
    margin: 0;
    overflow: hidden;   
}

.actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.addButton {
    color: #fff;
    background-color: #004dff;
    border-color: #004dff;
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    border-radius: 0.375rem;
    font-size: .75rem;
}

.edit {
    color: #fff;
    background-color: #a4a4a4;
    border-color: #a4a4a4;
}

.order { 
    color: #000000;
    background-color: transparent;
    border-color: transparent;
    font-size: .75rem;
    display: flex;
    cursor: pointer;
}

.delete {
    color: #fff;
    background-color: #f5365c;
    border-color: #f5365c;
    margin: 0 12px;
}

.tableFooter {
    padding: 1.5rem 1.5rem;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
}

.thCenter{
    padding: 8px 16px;
    max-height: 64px;
    text-align: start;
}

.noData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}   

.noData p {
    color: #6e6e6e;
    margin: 0;
}

.hidden {
    display: none;
}



.tdModalities {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin: auto 0;

    overflow: auto;
    max-width: 100px;
    min-width: 100px;
    width: 100%;
    padding: 10px 24px;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.tdModalities::-webkit-scrollbar {
    display: none;
  }
  
.tdModalities input[type="checkbox"] {
    margin: 0 auto;
}

.alert {
    position: fixed;
    top: 48px;
    left: calc(250px + 10%);

    display: flex;
    flex-direction: row;
    gap: 4px;

    background: #000;
    color: #fff;

    text-align: left;

    width: calc(80% - 250px);
    z-index: 9999;
}

.alertIcon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

@media (min-width: 768px) {
    .root {
        padding: 39px;
    }
}

@media (min-width: 992px) {
    .root {
        padding: 0 39px;
        margin-top: -100px;
    }

    .titleTh {
        width: 100%!important;
    }    

    .th {
        width: 20%;
        padding: 16px 24px;
    }

    .td {
        width: 20%;
        padding: 10px 24px;
    }

    .thCenter {
        width: 20%;
        padding: 10px 24px;
    }

    .titleCol {
        width: 100%;
        padding: 10px 24px;
    }
}
/*
css.td, css.actionButtons

*/