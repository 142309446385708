.root {
    padding: 0 15px;
    margin-top: -10rem;
}

.confirmButton {
    background-color: #f5365c;
    border: 1px solid #f5365c;
    border-radius: 0.25em;
    color: #fff;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
}

.cancelButton {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 6px;
    color: #000;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px transparent;
    font-weight: 500;
    cursor: pointer;
}

.warningIcon {
    width: 72px!important;
    height: 72px!important;
    border: none!important;
    border-radius: 0!important;
    background-image: url('../../assets/warning.png');
}

.modailities {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin: auto 0;

    overflow: auto;
    max-width: 350px;
    min-width: 350px;
    width: 100%;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.modailities::-webkit-scrollbar {
    display: none;
  }

.tag {
    background: #F4F4F4;
    color: #737E98;
    font-size: 14px;
    border-radius: 4px;
    padding: 2px 6px;
    margin: 0 4px;
    text-transform: capitalize;
    white-space: nowrap;
}

.tagActive {
    background: #64E3D2;
    color: #0C6458;
}

.tableContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
    transition: all 0.15s ease;
}

.tableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
    padding: 1.25rem 1.5rem;
    margin-bottom: 0;
    background-color: #fff;
}

.title {
    margin: 0;
}

.table {
    align-items: center;

    width: 100%;
    overflow-x: auto;
}

.thead, tbody {
    width: 100%;
}

.titleCol {
    display: flex;
    flex-direction: column;

    overflow: hidden;
    padding: var(--cell-padding-top-bottom) var(--cell-padding-sides);
    margin: 0;
    height: 64px;
    width: 300px;
}

.tr {
    max-height: 64px;
    border-bottom: 1px solid rgba(0,0,0,.05)
}

.th {
    background-color: var(--blueGray);
    color: var(--darkGray);

    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 10.4px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #e9ecef;
}

.th, .td {
    padding: var(--cell-padding-top-bottom) var(--cell-padding-sides);
    max-height: 64px;
}

.td {
    font-size: 14px;
}

.name {
    font-weight: 600;
}

.actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.addButton {
    color: #fff;
    background-color: #004dff;
    border-color: #004dff;
    padding: 12px 10px;
    line-height: 1.5;
    border-radius: 0.375rem;
    font-size: .75rem;
}

.edit {
    color: #fff;
    background-color: #11cdef;
    border-color: #11cdef;
}

.delete {
    color: #fff;
    background-color: #f5365c;
    border-color: #f5365c;
}

.tableFooter {
    padding: 1.5rem 1.5rem;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
}

.noData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}   

.noData p {
    color: #6e6e6e;
    margin: 0;
}

.searchGroup {
    display: flex;
    align-items: end;
    width: 100%;
    margin-left: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.375rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    border: 0;
    transition: box-shadow .15s ease;
}

.input {
    width: 100%;
    padding: 0.625rem 0.75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e6e6e;
    background-color: #fff;
    border: 0;
    margin: auto 0;
}

.iconHolder {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 0.625rem 0.75rem;
}

.icon {
    display: flex;
    align-items: center;
    margin: auto 0;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.5;
    color: #adb5bd;
    text-align: center;
    transition: all .2s cubic-bezier(.68,-.55,.265,1.55);
}
.searchButton{ 
    align-items: center;
    margin: auto 6px;
    color: #004dff;
    background-color: #fff;
    border-color: #004dff;
    padding: 12px 10px;
    width: max-content;
    line-height: 1.5;
    border-radius: 0.375rem;
    font-size: .75rem;
    text-align: center;
}

@media (min-width: 768px) {
    .root {
        padding: 39px;
    }

    .titleCol {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .root {
        padding: 0 39px;
        margin-top: -100px;
    }
}