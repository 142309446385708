.card {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
    padding: 0 32px;
}

.cardButton {
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0 12px;
    user-select: none;
    margin-bottom: 24px;
    width: 100%;
}

.image {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 15px;

    object-fit: cover;
    width: 100%;
}

.contentCard {
    color: #000;

    margin-bottom: 24px;
    width: 100%;
}

.titleCard {
    font-size: 40px;
    font-weight: 700;
    bottom: 0;
    line-height: 1.334;
    z-index: 1;
}